<template>
  <div id="send-mx">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="page-icon">
            <span class="fa-layers fa-fw">
              <i class="fa-solid fa-credit-card-blank" data-fa-transform=""></i>
              <i
                class="fak fa-mx-sign-fat text-light"
                data-fa-transform="shrink-10 up-3 right-6"
              ></i>
            </span>
          </div>
          <div class="header-like flex-fill" v-if="!isGiftCard">
            &nbsp;Charge a Card
          </div>
          <div class="header-like flex-fill" v-else>&nbsp;Charge Gift Card</div>
        </div>
        <div class="close-btn">
          <div @click="closePane">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <goodbye
        class="mt-3"
        style="
          padding-top: 15px;
          margin-left: -30px;
          margin-bottom: -30px;
          width: 100%;
        "
      />
      <div v-if="trialExchanges.includes(user?.profile?.exchangeId)" class="">
        <div class="alert alert-warning d-flex flex-column gap-3 mt-3">
          <div>
            Your account is currently in trial mode and is not allowed to accept
            Moxey from other members
          </div>
          <div>
            To accept Moxey, you'll need to upgrade to full Moxey membership.
          </div>
          <button
            type="button"
            class="btn btn-secondary m-0"
            :disabled="processing"
            @click="backToAccount"
          >
            Go Back
          </button>
        </div>
      </div>
      <form
        v-else
        @keypress.enter.prevent
        @submit.prevent
        class="sendmx-form"
        v-if="user"
        id="ccForm"
        v-show="!receipt && !giftCardReceipt"
      >
        <div class="section-title">
          {{ isGiftCard ? "Charge Gift Card" : "Charge Member" }}
          <span class="text-muted"></span>
        </div>

        <div
          id="contact-search"
          class="input-group"
          v-if="!giftCardInfo && !gettingBalance"
        >
          <ContactSearch
            :showQR="true"
            :disabled="processing"
            @selected-contact="handleSelectedContact"
            :invalid="!!invalidFields.contact"
            @didFocus="
              invalidFields.contact = undefined;
              doSearchFocus();
            "
            @didUpdate="(v) => (contactValue = v)"
            :clearSearch="clearContactSearch"
            :allowSearch="false"
            :placeholder="'Enter card number'"
            @isGiftCard="
              (c) => {
                isGiftCard = c;
                if (isGiftCard) getGiftCardBalance();
              }
            "
            @searchActive="
              (a) => {
                if (!a) {
                  chargeMember = undefined;
                }
              }
            "
          />
        </div>
        <div>
          <div class="card-info" v-if="chargeMember">
            Moxey Card: {{ cardDisplay }}
          </div>
          <div class="card-info text-danger" v-if="invalidFields.contact">
            <small>{{ invalidFields.contact }}</small>
          </div>
        </div>
        <div v-if="giftCardInfo && !gettingBalance" class="gc-info d-flex">
          <div class="flex-fill">
            <div class="card-info">Gift Card: {{ cardDisplay }}</div>
            <div class="card-info">
              Remaining gift card balance:
              <span style="white-space: nowrap">
                <i
                  class="fak fa-mx-sign-fat"
                  data-fa-transform="shrink-3 right-2"
                ></i
                >{{ giftCardInfo.giftCardBalance }}
              </span>
            </div>
            <div v-if="dueAmount > 0" class="card-info text-danger">
              There will be an additional
              <i class="fas fa-dollar-sign" data-fa-transform="shrink-3"></i
              >{{ dueAmount }} due after the gift card is processed.
            </div>
          </div>
          <div
            class="clear-contact"
            @click="
              () => {
                isGiftCard = false;
                giftCardInfo = undefined;
              }
            "
          >
            <i class="fas fa-times text-danger"></i>
          </div>
        </div>
        <div v-if="gettingBalance" class="gc-info d-flex">
          <div>
            <i class="fas fa-sync fa-spin"></i> Checking gift card balance
          </div>
        </div>

        <div v-if="user.products && user.products.length > 0">
          <div class="section-title">
            Service / Product Sold<span class="text-muted"></span>
          </div>

          <select
            id="productId"
            name="productId"
            v-model="productId"
            class="form-select"
            @focus="clearError('productId')"
            :class="{ 'is-invalid': invalidFields.productId }"
            placeholder="Product Sold"
            :disabled="processing"
          >
            <option v-for="(p, i) in user.products" :key="i" :value="p.id">
              {{ p.description }}
            </option>
          </select>
        </div>

        <div class="section-title">
          Moxey Amount<span class="text-muted"></span>
        </div>
        <div class="input-group">
          <span class="input-group-text"
            ><i class="fak fa-mx-sign-fat fa-fw"></i
          ></span>
          <input
            type="number"
            class="form-control"
            inputmode="decimal"
            :class="{
              'is-invalid': invalidFields.amount || invalidFields.mxBalance,
            }"
            id="amount"
            min="0"
            step="0.01"
            name="amount"
            v-model="amount"
            placeholder="Amount"
            @scroll="
              (e) => {
                e.preventDefault();
              }
            "
            @keydown="
              (e) => {
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown')
                  e.preventDefault();
              }
            "
            @focus="
              () => {
                amount = undefined;
                clearError('amount');
              }
            "
            @blur="sanitizeAmount"
            aria-label="Amount"
            :disabled="processing"
          />
        </div>
        <div class="text-danger" v-show="invalidFields.amount">
          <small>{{ invalidFields.amount }}</small>
        </div>

        <div class="section-title" v-if="!isGiftCard">
          Tip<span class="text-muted"></span>
        </div>
        <div class="input-group" v-if="!isGiftCard">
          <span class="input-group-text"
            ><i class="fas fa-dollar-sign fa-fw"></i
          ></span>
          <input
            type="number"
            inputmode="decimal"
            class="form-control"
            :class="{
              'is-invalid': invalidFields.tip,
            }"
            id="tip"
            min="0"
            step="0.01"
            name="tip"
            v-model="tip"
            placeholder="Tip"
            @scroll="
              (e) => {
                e.preventDefault();
              }
            "
            @keydown="
              (e) => {
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown')
                  e.preventDefault();
              }
            "
            @blur="sanitizeTip"
            @focus="
              () => {
                tip = undefined;
                clearError('tip', 'cashBalance');
              }
            "
            aria-label="Tip Amount"
            :disabled="processing"
          />
        </div>
        <div class="text-danger" v-show="invalidFields.tip">
          <small>{{ invalidFields.tip }}</small>
        </div>

        <div class="section-title">Note<span class="text-muted"></span></div>
        <textarea
          class="form-control"
          id="note"
          name="note"
          v-model="note"
          rows="3"
          :disabled="processing"
        ></textarea>

        <div>
          <div class="text-danger mt-2" v-show="transactionError">
            <small v-if="transactionError === 'LOW_CRA'">
              The amount in member's Cash Reserve Account is not enough to cover
              this transaction.
              <router-link to="/banking/loadcra"
                >Click here to reload your Cash Reserve Account.</router-link
              >
            </small>
            <small v-else
              >Unable to process this transaction at this time. Reason:
              {{ transactionError }}</small
            >
          </div>

          <button
            v-show="!processing"
            type="submit"
            class="btn btn-success"
            :disabled="processing || gettingBalance"
            @click="doValidate"
          >
            {{ !isGiftCard ? "Accept Moxey" : "Accept Gift Card" }}
          </button>
          <button v-show="processing" class="btn btn-success" disabled>
            <i class="fas fa-sync fa-spin"></i>
            {{ !isGiftCard ? "Charging Card" : "Charging Gift Card" }}
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            :disabled="processing"
            @click="backToAccount"
          >
            Cancel
          </button>
        </div>
      </form>
      <div v-if="receipt" class="receipt">
        <div class="section-title">You charged {{ receipt.chargeMember }}.</div>
        <div class="section-text">Transaction details:</div>
        <div class="transaction-details">
          <div class="section-text" v-if="receipt.sold">
            Service / Product Sold: {{ receipt.sold }}
          </div>
          <div class="section-text">
            Amount:
            <i
              class="fak fa-mx-sign-fat"
              data-fa-transform="shrink-3 right-2"
            ></i
            >{{ receipt.amount }}
          </div>
          <div class="section-text">
            Tip: <i class="fas fa-dollar-sign" data-fa-transform="shrink-3"></i
            >{{ receipt.tip }}
          </div>
          <div class="section-text">Date: {{ receipt.date }}</div>
          <div class="section-text">
            Confirmation: {{ receipt.moxeyPayConfirm }}
          </div>
          <div class="section-text">Note: {{ receipt.note }}</div>
        </div>
        <div class="section-text">
          <span class="receipt-print" @click="printDiv('receipt-print')">
            Print receipt for your records
          </span>
        </div>
        <button class="btn btn-secondary close-btn" @click="clearReceipt">
          Close
        </button>

        <div id="receipt-print">
          <h3 class="header-like">Moxey Member Charged!</h3>

          <p>You charged {{ receipt.chargeMember }}.</p>
          <p v-if="receipt.sold">Service / Product Sold: {{ receipt.sold }}</p>
          <p>Transaction details:</p>
          <p>
            Amount:
            <i
              class="fak fa-mx-sign-fat"
              data-fa-transform="shrink-3 right-2"
            ></i
            >{{ receipt.amount }}
          </p>
          <p>Tip: ${{ receipt.tip }}</p>
          <p>Date: {{ receipt.date }}</p>
          <p>Confirmation: {{ receipt.moxeyPayConfirm }}</p>
          <p>Note: {{ receipt.note }}</p>
        </div>
      </div>

      <div v-if="giftCardReceipt" class="receipt">
        <div class="section-title">You charged a Moxey gift card.</div>
        <div class="section-text">Transaction details:</div>
        <div class="transaction-details">
          <div class="section-text" v-if="giftCardReceipt.sold">
            Service / Product Sold: {{ giftCardReceipt.sold }}
          </div>
          <div class="section-text">
            Amount:
            <i
              class="fak fa-mx-sign-fat"
              data-fa-transform="shrink-3 right-2"
            ></i
            >{{ giftCardReceipt.amount }}
          </div>
          <div
            class="section-text"
            :class="{ 'text-danger': giftCardReceipt.due > 0 }"
          >
            Remaining Balance Due:
            <i class="fas fa-dollar-sign" data-fa-transform="shrink-3"></i
            >{{ giftCardReceipt.due }}
          </div>
          <div class="section-text">
            Remaining Gift Card Balance:
            <i
              class="fak fa-mx-sign-fat"
              data-fa-transform="shrink-3 right-2"
            ></i
            >{{ giftCardReceipt.balance }}
          </div>
          <div class="section-text">Date: {{ giftCardReceipt.date }}</div>
          <div class="section-text">
            Confirmation: {{ giftCardReceipt.moxeyPayConfirm }}
          </div>
          <div class="section-text">Note: {{ giftCardReceipt.note }}</div>
        </div>
        <div class="section-text">
          <span class="receipt-print" @click="printDiv('gift-receipt-print')">
            Print receipt for your records
          </span>
        </div>
        <button class="btn btn-success close-btn" @click="clearReceipt">
          Close
        </button>

        <div id="gift-receipt-print">
          <h3 class="header-like">Moxey Gift Card Charged!</h3>

          <p>You charged a Moxey gift card.</p>
          <p v-if="giftCardReceipt.sold">
            Service / Product Sold: {{ giftCardReceipt.sold }}
          </p>
          <p>Transaction details:</p>
          <p>
            Amount:
            <i
              class="fak fa-mx-sign-fat"
              data-fa-transform="shrink-3 right-2"
            ></i
            >{{ giftCardReceipt.amount }}
          </p>
          <p>Remaining Balance Due: ${{ giftCardReceipt.due }}</p>
          <p>
            Remaining Gift Card Balance:
            <i
              class="fak fa-mx-sign-fat"
              data-fa-transform="shrink-3 right-2"
            ></i
            >{{ giftCardReceipt.balance }}
          </p>
          <p>Date: {{ giftCardReceipt.date }}</p>
          <p>Confirmation: {{ giftCardReceipt.moxeyPayConfirm }}</p>
          <p>Note: {{ giftCardReceipt.note }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Just a comment to test
import { mapState, mapActions } from "vuex";
import { log, api, gaEvent, gaError } from "@/utils/utils";
import ContactSearch from "@/components/CashApp/Shared/ContactSearch.vue";
import Goodbye from "@/components/Shared/Goodbye.vue";
import currency from "currency.js";
import dayjs from "dayjs";

export default {
  name: "ChargeCard",
  components: {
    ContactSearch,
    Goodbye,
  },
  data() {
    return {
      chargeMember: undefined,
      amount: undefined,
      tip: undefined,
      note: "",
      invalidFields: {},
      processing: false,
      transactionError: undefined,
      receipt: undefined,
      giftCardReceipt: undefined,
      clearContactSearch: 0,
      contactValue: undefined,
      productId: undefined,

      isGiftCard: false,
      gettingBalance: false,
      giftCardInfo: undefined,
    };
  },
  props: {},
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      user: (state) => state.app.all.user,
      userIcon: (state) => state.app.all.userIcon,
      memberIcon: (state) => state.app.all.memberIcon,
      testMode: (state) => state.app.all.testMode,
      trialExchanges: (state) => state.app.all.trialAccountExchanges,
    }),
    cardDisplay() {
      return (
        (this.chargeMember || this.isGiftCard) &&
        this.contactValue
          .toString()
          .split("")
          .reduce((str, l, i) => {
            if (i < 4 || i > this.contactValue.toString().length - 5) {
              str.push(l);
            } else {
              str.push("*");
            }
            return str;
          }, [])
          .join("")
      );
    },
    dueAmount() {
      return currency(
        currency(this.giftCardInfo.giftCardBalance).subtract(
          currency(this.amount)
        ).value * -1,
        { symbol: "" }
      ).format();
    },
  },
  watch: {
    isGiftCard(post) {
      if (post) {
        this.tip = undefined;
      }
    },
  },
  methods: {
    clearReceipt() {
      this.receipt = undefined;
      this.giftCardReceipt = undefined;
    },
    handleSelectedContact(c) {
      this.chargeMember = c;
    },
    clearForm() {
      this.chargeMember = undefined;
      this.amount = undefined;
      this.tip = undefined;
      this.note = "";
      this.invalidFields = {};
      this.transactionError = false;
      this.clearContactSearch = this.clearContactSearch + 1;
      this.productId =
        this.user.products &&
        this.user.products.length > 0 &&
        this.user.products[0].id;
      this.isGiftCard = undefined;
      this.giftCardInfo = undefined;
      this.contactValue = undefined;
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
    doValidate() {
      this.transactionError = undefined;
      this.invalidFields = {};

      if (!this.chargeMember && !this.isGiftCard)
        this.invalidFields.contact = "This card number is invalid.";

      if (!this.amount || this.amount === "")
        this.invalidFields.amount = "You must specify an amount to charge.";
      else if (this.amount < 0)
        this.invalidFields.amount = "Moxey amount must be greater than zero.";

      if (this.tip < 0)
        this.invalidFields.tip = "Tip amount must be greater than zero.";

      if (Object.keys(this.invalidFields).length === 0) {
        if (this.isGiftCard) {
          this.chargeGiftCard();
        } else {
          this.chargeCard();
        }
      }
    },
    sanitizeAmount() {
      if (this.amount < 0) {
        this.invalidFields.amount = "Moxey amount must be greater than zero.";
        this.amount = "";
      } else if (!this.amount || this.amount === "") {
        this.amount = currency(0);
      } else if (this.amount && !/^\d*\.?\d*$/.test(this.amount)) {
        this.invalidFields.amount = "Invalid amount.";
        this.amount = currency(0);
      } else {
        this.amount = currency(this.amount);
      }
    },
    sanitizeTip() {
      if (this.tip < 0) {
        this.invalidFields.tip = "Tip amount must be greater than zero.";
        this.tip = "";
      } else if (!this.tip || this.tip === "") {
        this.tip = currency(0);
      } else if (this.tip && !/^\d*\.?\d*$/.test(this.tip)) {
        this.invalidFields.tip = "Invalid tip.";
        this.tip = currency(0);
      } else {
        this.tip = currency(this.tip);
      }
    },
    clearError(k, l) {
      this.invalidFields[k] = undefined;
      this.invalidFields[l] = undefined;
    },
    async chargeCard() {
      this.processing = true;

      const sellerInfo = {
        sellerMainName: [this.user.contact.fName, this.user.contact.lname].join(
          " "
        ),
        sellerSubName: this.user.member.public.listName,
        sellerMainIcon: this.userIcon,
        sellerSubIcon: this.memberIcon,
      };

      const buyerInfo = {
        buyerUserName: this.chargeMember.list_name,
        buyerMemberName: this.chargeMember.desc,
        buyerUserIcon: this.chargeMember.contactIcon,
        buyerMemberIcon:
          this.chargeMember.id.substring(0, 1) === "c" && this.subIcon,
      };

      try {
        if (this.testMode)
          throw { error: "This is a test account and will not process Moxey" };
        const res = await api("post", "banking", `/v2/moxey/charge/`, {
          body: {
            chargeTarget: this.chargeMember.id,
            amount: this.amount,
            tip: this.tip,
            note: this.note,
            productId: this.productId,
            receiptInfo: {
              ...buyerInfo,
              ...sellerInfo,
              product:
                this.user.products &&
                this.user.products.length > 0 &&
                this.user.products.find((p) => p.id === this.productId)
                  ?.description,
            },
          },
        });

        this.updateAppStore({
          user: {
            ...this.user,
            account: res.account,
          },
        });

        this.receipt = {
          sold:
            this.user.products &&
            this.user.products.length > 0 &&
            this.user.products.find((p) => p.id === this.productId)
              ?.description,
          chargeMember: this.chargeMember.list_name,
          amount: currency(this.amount, { symbol: "" }).format(),
          tip: currency(this.tip, { symbol: "" }).format(),
          date: dayjs(Date.now()).format("M/D/YYYY h:mm A"),
          note: this.note,
          moxeyPayConfirm: res.moxeyPayConfirm,
        };

        const logData = {
          m: "Charged a card.",
          amt: this.amount,
          tip: this.tip,
          pid: this.chargeMember.id,
          mn:
            this.chargeMember.id.substring(0, 1) === "m"
              ? this.chargeMember.list_name
              : this.chargeMember.desc,
          cn:
            this.chargeMember.id.substring(0, 1) === "m"
              ? this.chargeMember.desc
              : this.chargeMember.list_name,
          partition: "banking",
        };

        gaEvent(
          "charge_card",
          "banking",
          `${buyerInfo.buyerUserName} (${buyerInfo.buyerMemberName}) => ${sellerInfo.sellerSubName} (${sellerInfo.sellerMainName}) amt:${logData.amt} tip:${logData.tip}`
        );

        log(logData);

        this.clearForm();
      } catch (e) {
        gaError(`charge_card: ${e.error}`);
        if (e.error === "Insufficient CRA") {
          this.transactionError = "LOW_CRA";
        } else {
          this.transactionError = e.error || e;
        }
        log({
          m: "Error charging card.",
          amt: this.amount,
          tip: this.tip,
          pid: this.chargeMember.id,
          mn:
            this.chargeMember.id.substring(0, 1) === "m"
              ? this.chargeMember.list_name
              : this.chargeMember.desc,
          cn:
            this.chargeMember.id.substring(0, 1) === "m"
              ? this.chargeMember.desc
              : this.chargeMember.list_name,
          e: e.message || e.status,
          partition: "banking",
        });
      } finally {
        this.processing = false;
      }
    },
    async getGiftCardBalance() {
      if (!this.user.member.public.giftCard) {
        this.invalidFields.contact =
          "You are not currently accepting Moxey gift cards. Please request another form of payment.";
        this.isGiftCard = false;
        return;
      }

      this.giftCardInfo = undefined;
      if (!this.gettingBalance) {
        this.gettingBalance = true;

        try {
          const res = await api(
            "get",
            "banking",
            `/v2/moxey/giftcard?giftCardNumber=${this.isGiftCard}`
          );

          if (res.expired === 1) {
            throw {
              error: `This gift card is expired.`,
            };
          } else if (res.error) {
            this.invalidFields.contact = res.error;
            this.isGiftCard = false;
          } else {
            if (res.balance <= 0) {
              this.invalidFields.contact =
                "Gift card has no remaining balance.";
              this.isGiftCard = false;
            } else {
              this.contactValue = "" + this.isGiftCard;
              res.giftCardBalance = currency(res.balance, {
                symbol: "",
              }).format();
              this.giftCardInfo = res;
            }
          }
        } catch (e) {
          this.invalidFields.contact = e.error || e;
          this.isGiftCard = false;
        } finally {
          this.gettingBalance = false;
        }
      }
    },
    async chargeGiftCard() {
      this.processing = true;

      try {
        if (this.testMode)
          throw { error: "This is a test account and will not process Moxey" };
        const res = await api("post", "banking", `/v2/moxey/giftcard/`, {
          body: {
            giftCardNumber: this.isGiftCard,
            amount: this.amount,
            note: this.note,
            productId: this.productId,
          },
        });

        this.updateAppStore({
          user: {
            ...this.user,
            account: res.account,
          },
        });

        this.giftCardReceipt = {
          sold:
            this.user.products &&
            this.user.products.find((p) => p.id === this.productId)
              ?.description,
          amount: currency(this.amount, { symbol: "" }).format(),
          balance: currency(res.giftCard.balance, { symbol: "" }).format(),
          due: currency(res.giftCard.due, { symbol: "" }).format(),
          date: dayjs(Date.now()).format("M/D/YYYY h:mm A"),
          note: this.note,
          moxeyPayConfirm: res.moxeyPayConfirm,
        };

        log({
          m: "Charged a gift card.",
          amt: this.amount,
          partition: "banking",
        });

        gaEvent(
          "charge_gift_card",
          "banking",
          `${this.user.member.public.listName} (${[
            this.user.contact.fName,
            this.user.contact.lname,
          ].join(" ")}) amt:${this.amt}`
        );

        this.clearForm();
      } catch (e) {
        gaError(`charge_gift_card: ${e.error}`);
        this.transactionError = e.error || e;
        log({
          m: "Error charging gift card.",
          amt: this.amount,
          e: e.message || e.status,
          partition: "banking",
        });
      } finally {
        this.processing = false;
      }
    },
    printDiv(divName) {
      const printContents = document.getElementById(divName).innerHTML;
      const w = window.open();
      w.document.write(printContents);
      w.print();
      w.close();
    },
    closePane() {
      this.clearForm();
      this.clearReceipt();
      this.$emit("cancel");
    },
    backToAccount() {
      this.clearForm();
      this.clearReceipt();
      this.$emit("clickedShortcut", "Account");
    },
    doSearchFocus() {
      const top = document
        .getElementById("contact-search")
        .getBoundingClientRect().y;
      document.getElementById("left-bar").scroll({
        top: top - 55,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  beforeMount() {},
  mounted() {
    this.clearReceipt();
    this.clearForm();
    this.$nextTick(() => {
      this.productId =
        this.user.products &&
        this.user.products.length > 0 &&
        this.user.products[0].id;
    });
  },
};
</script>

<style scoped>
#send-mx {
  background-color: white;
  color: var(--ms-dark);
  font-size: 18px;
  line-height: 22px;
}
.sidebar-content {
  padding: 30px 0;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

form {
  position: relative;
  margin-top: 35px;
  width: 100%;
  height: 100%;
  padding: 0 30px;
}

.input-group,
.form-control:not(.input-group .form-control),
select {
  margin: 15px 0;
}

.input-group-text {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.section {
  margin: 60px 0 0;
  padding: 30px 30px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.section:last-child {
  margin: 60px 0;
}

.section-title {
  font-size: 20px;
  line-height: 24px;
}

.section-title span {
  font-size: 16px;
  margin: 0 0 0 10px;
  font-style: italic;
}

.section-title {
  margin: 30px 0 0;
}

.form-control {
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  font-style: italic;
}

.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-check-input {
  padding: 12px;
  height: 24px;
  width: 24px;
  margin: 0;
}

.form-check-label {
  margin: 0 0 0 20px;
}

.check-control {
  margin: 15px 0;
  display: flex;
}

select {
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  font-style: italic;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
}

.expiration:not(:last-of-type) {
  margin: 15px 20px 15px 0;
}

.btn-success {
  margin: 35px 0;
  border-color: var(--mx-green);
  background-color: var(--mx-green);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.btn-secondary {
  margin: 15px 0 35px;
  border-color: var(--mx-grey);
  background-color: var(--mx-grey);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.error {
  font-size: 18px;
  line-height: 27px;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.section-text {
  margin: 15px 0;
}

.receipt-print {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}

#receipt-print,
#gift-receipt-print {
  display: none;
}

.receipt {
  width: 100%;
  padding: 60px 30px 0;
}

.transaction-details {
  padding: 15px 15px;
}

.gc-info {
  margin: 15px 0 0;
  line-height: 28px;
}

.clear-contact {
  font-size: 24px;
  cursor: pointer;
  margin: 0 0 0 15px;
}

.card-info:not(:first-child) {
  margin: 15px 0 0 0;
}

.card-info:last-child {
  margin: 15px 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
