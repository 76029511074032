<template>
  <div class="account">
    <div v-if="!isPartial">
      <div v-if="showSpendingTarget">
        <spending-target
          :user="user"
          @clickedShortcut="
            (e) => {
              $emit('clickedShortcut', e);
            }
          "
        />
      </div>

      <div class="d-flex bp-outer">
        <div class="cra flex-fill">
          <div class="d-flex justify-content-start align-items-center">
            <div class="bp-title fw-lighter">Buying Power</div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <div class="bp-amt text-center">
              <moxey-value
                :value="user.account.availableBalance"
                :pretty="true"
              />
            </div>
            <div
              class="whats-this ms-2"
              @click="$emit('clickedShortcut', 'BuyingPowerInfo')"
            >
              <i class="fas fa-circle-question"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 d-flex cra-outer">
          <div class="cra flex-fill">
            <div class="d-flex justify-content-start align-items-center">
              <div class="cra-title fw-lighter">Moxey Balance</div>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <div class="cra-amt text-center">
                <moxey-value
                  :value="user.account.tradeBalance"
                  :pretty="true"
                />
              </div>
              <div
                class="whats-this ms-2"
                @click="$emit('clickedShortcut', 'BalanceInfo')"
              >
                <i class="fas fa-circle-question"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 d-flex cra-outer">
          <div class="cra flex-fill">
            <div class="d-flex justify-content-start align-items-center">
              <div class="cra-title fw-lighter">Cash Reserve Account</div>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <div class="cra-amt text-center">
                <usd-value :value="user.account.cashBalance" :pretty="true" />
              </div>
              <div
                class="whats-this ms-2"
                @click="$emit('clickedShortcut', 'CRAInfo')"
              >
                <i class="fas fa-circle-question"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mx-alert d-flex align-items-center">
        <div>
          <span class="fa-layers fa-fw">
            <i class="fa-solid fa-dollar-sign"></i>
            <i
              class="fa-solid fa-up text-success"
              data-fa-transform="shrink-5 right-5 down-6"
            ></i>
          </span>
        </div>
        <div v-if="craEnrollment" class="alert-text cra-enroll-text">
          You are currently
          <span class="fw-bold text-success">ENROLLED</span> in Cash Reserve
          Auto-Reload. Once your account balance falls below zero your account
          will automatically have another ${{ craEnrollment.autoReloadAmount }}
          added.
        </div>
        <div v-else class="alert-text cra-enroll-text">
          You are currently
          <span class="fw-bold text-success">NOT ENROLLED</span> in Cash Reserve
          Auto-Reload.
          <span
            class="mx-alert-link"
            @click="
              updateAppStore({
                leftBarComp: 'AutoLoad',
                forwardCard: undefined,
              })
            "
            >Avoid declined transactions by clicking here and enrolling
            now</span
          >.
        </div>
      </div>

      <!-- <div class="refresh-button">
      <button
        v-if="!refreshing"
        class="btn btn-outline-light"
        @click="refreshUser"
      >
        <i class="fa fa-sync"></i> Refresh
      </button>
      <button v-else disabled class="btn btn-outline-light">
        <i class="fa fa-sync fa-spin"></i> Refresh
      </button>
    </div> -->

      <div v-if="user?.account?.flashCashEnroll">
        <div class="divider"></div>
        <flash-cash :mini="true" />
      </div>
      <div class="divider"></div>

      <!-- <div class="mx-alert d-flex align-items-center">
        <div class="text-warning promo-icon">
          <i class="fa-duotone fa-sparkles"></i>
        </div>
        <div class="promo-text ms-3">
          <div class="fw-bolder mb-1">
            Introducing Moxey<strong><em>Score</em></strong
            >!
          </div>
          <div class="fw-light">
            Moxey<strong><em>Score</em></strong> is a new tool that will help
            you get the most out of your Moxey membership.
            <span
              class="mx-alert-link"
              @click="
                $router.push('account/dashboard');
                $emit('closeClicked');
              "
              >Click here to check it out</span
            >.
          </div>
        </div>
      </div> -->

      <div class="mb-4" v-if="user">
        <mx-score :summary="true" chartId="scoreLeftBar" />
      </div>

      <div class="divider" v-if="user?.pointsSetup?.exchangeEnrolled"></div>

      <div
        class="d-flex cra-outer my-4"
        v-if="user?.pointsSetup?.exchangeEnrolled"
      >
        <div class="cra flex-fill">
          <div class="d-flex justify-content-between align-items-center">
            <div>Community Rewards</div>
            <div class="whats-this" @click="$emit('clickedShortcut', 'CRInfo')">
              <i class="fas fa-circle-question"></i>
            </div>
          </div>
          <div class="cra-amt d-flex justify-content-center mt-3">
            <reward-balance />
          </div>

          <div
            class="mt-3 fw-light text-center"
            style="font-size: 0.9rem; line-height: 0.9rem"
          >
            <a href="/rewards"> Manage & Redeem Rewards </a>
          </div>
        </div>
      </div>

      <div class="divider" v-show="craThreshold || health"></div>

      <div class="mx-alert d-flex align-items-center" v-if="craThreshold">
        <div>
          <span class="fa-layers fa-fw">
            <i class="fa-solid fa-dollar-sign"></i>
            <i
              class="fa-solid fa-down text-danger"
              data-fa-transform="shrink-5 right-5 down-6"
            ></i>
          </span>
        </div>
        <div class="alert-text">
          Your Cash Reserve Account balance is below the recommended threshold.
          <span
            class="mx-alert-link"
            @click="$emit('clickedShortcut', 'LoadCRA')"
            >Avoid declined transactions by clicking here to reload now</span
          >.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/awsconfig";
import currency from "currency.js";
import { mapState, mapActions } from "vuex";
import MxScore from "../../../Account/MxScore.vue";
import RewardBalance from "../../../Shared/rewards/RewardBalance.vue";
import FlashCash from "../../../Account/FlashCash.vue";
import MoxeyValue from "../../../Shared/moxey/MoxeyValue.vue";
import UsdValue from "../../../Shared/usd/UsdValue.vue";
import SpendingTarget from "./SpendingTarget.vue";

export default {
  name: "AccountSummary",
  components: {
    MxScore,
    RewardBalance,
    FlashCash,
    MoxeyValue,
    UsdValue,
    SpendingTarget,
  },
  props: {},
  data() {
    return {
      mainImageError: false,
      subImageError: 0,
      subImageLoading: true,
    };
  },
  computed: {
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      user: (state) => state.app.all.user,
      loggingIn: (state) => state.app.all.loggingIn,
      refreshing: (state) => state.app.all.refreshing,
    }),
    avatar() {
      return `${config.cloudFrontPrefix}/v2/users/${this.user.contact.private.userid}/avatars/avatar.jpg?ts=${this.user.updateTimestamp}`;
    },
    icon_v2() {
      return `${config.cloudFrontPrefix}/v2/images/products/${this.user.member.memberId}/icon.jpg?ts=${this.user.member.updateTimestamp}`;
    },
    noProduct() {
      return !this.user.member.products;
    },
    icon() {
      const icons =
        this.user.member.products &&
        this.user.member.products.map(
          (p) => `/products/${p.productId}/icon_${p.productId}.${p.iconExt}`
        );
      return icons && icons[0] && `${config.cloudFrontPrefix}${icons[0]}`;
    },
    buyingPower() {
      return currency(this.user.account.availableBalance, { symbol: "" })
        .format()
        .split(".");
    },
    cra() {
      return currency(this.user.account.cashBalance, { symbol: "" })
        .format()
        .split(".");
    },
    craEnrollment() {
      for (const k in this.user?.wallet) {
        if (this.user.wallet[k]?.autoReloadAmount) {
          return this.user.wallet[k];
        }
      }
      return false;
    },
    craThreshold() {
      if (
        this.user.account.cashBalance <
        parseFloat(this.user.member.private.cashBalanceThresh)
      ) {
        return true;
      }

      return false;
    },
    health() {
      return this.user.member.private.balanceHealth;
    },
    isPartial() {
      return this.user && this.user.profile.isPartialMember;
    },
    showSpendingTarget() {
      return this.user?.exchangeFlags?.showSpendingTarget;
    },
  },
  mounted() {
    console.log(this.user);
  },
  methods: {
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
    ...mapActions("app", ["refreshUser"]),
  },
  beforeMount() {
    this.subImageError = 0;
  },
};
</script>

<style scoped>
.account {
  width: 100%;
}

.icons {
  position: relative;
}

.biz-icon {
  background-color: var(--mx-dark);
  color: var(--mx-white);
}

.person-icon {
  background-color: var(--mx-blue);
  color: var(--mx-dark);
}

.contact-icon {
  width: 60px;
  height: 60px;
  min-width: 60px;
  margin: 0 20px 0 0;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  border: 1px solid #a6a6a630;
}

.contact-icon div,
.sub-image div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
}

.contact-icon img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sub-image div {
  font-size: 12px;
  line-height: 30px;
}

.contact-icon img,
.sub-image img {
  height: 100%;
}

.sub-image {
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 15px;
  margin: 0 8px 0 0;
  overflow: hidden;
  border: 1px solid #a6a6a630;
  position: absolute;
  right: 0;
  top: 40px;
}

.name {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}

.sub-name {
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  margin-top: 11px;
}

.close-btn div {
  cursor: pointer;
}

.divider {
  width: 100%;
  background-color: var(--mx-white);
  height: 1px;
  margin: 20px 0;
}

.buying-power {
  font-size: 24px;
  font-weight: 400;
}

.bp-title {
  font-size: 20px;
}

.bp-amt {
  margin: 5px 0 10px;
  font-size: 42px;
  line-height: 42px;
}

.bp-amt .cents {
  font-size: 0.6em;
  line-height: 22px;
  padding: 0 0 0 8px;
}

.whats-this {
  font-size: 18px;
  cursor: pointer;
}

.cra-outer {
}

.cra-title {
  font-size: 14px;
}

.cra {
  font-size: 18px;
  font-weight: 400;
}

.cra-amt {
  margin: 0;
  font-size: 20px;
  line-height: 20px;
}

.cra-amt .cents {
  font-size: 14px;
  line-height: 16px;
  padding: 0 0 0 4px;
}

.mx-alert:not(:first-child) {
  margin: 15px 0 0;
}

.alert-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  margin: 0 0 0 10px;
}

.promo-text {
  font-size: 17px;
  line-height: 20px;
  font-weight: 300;
  margin: 0 0 0 10px;
}

.promo-icon {
  font-size: 50px;
}

.mx-alert-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}

.refresh-button {
  margin: 15px 0 0;
  text-align: center;
}

.refresh-button .btn {
  font-size: 14px;
  line-height: 17px;
  padding: 10px 20px;
  width: initial;
  border-radius: 20px;
}

.gem .letters {
  bottom: 0px;
  font-size: 0.95rem;
  color: darkgoldenrod;
}

a {
  color: var(--mx-white);
  text-decoration: dotted;
  border-bottom: 1px dotted;
}
</style>
