<template>
  <div id="send-mx">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center"
    >
      <div class="page-title d-flex justify-content-between">
        <div class="d-flex">
          <div class="page-icon">
            <span class="fa-layers fa-fw">
              <i
                class="fak fa-mx-sign-fat"
                data-fa-transform="shrink-2 up-3.5"
              ></i>
              <i
                class="fa-solid fa-share"
                data-fa-transform="shrink-2 down-6 left-3 flip-v"
              ></i>
            </span>
          </div>
          <div class="header-like flex-fill">&nbsp;Send Moxey</div>
        </div>
        <div class="close-btn">
          <div @click="closePane">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <goodbye
        class="mt-3"
        style="
          padding-top: 15px;
          margin-left: -30px;
          margin-bottom: -15px;
          width: 100%;
        "
      />
      <form
        @submit.prevent
        @keypress.enter.prevent
        class="sendmx-form"
        v-if="user"
        id="ccForm"
        v-show="!receipt"
      >
        <div v-if="!request">
          <div class="section-title">
            Send To<span class="text-muted"></span>
          </div>

          <div id="contact-search" class="input-group">
            <ContactSearch
              :showQR="true"
              :disabled="processing"
              @selected-contact="handleSelectedContact"
              :invalid="invalidFields.contact"
              :moxeyPayAllowed="true"
              @didFocus="
                invalidFields.contact = undefined;
                doSearchFocus();
              "
              :clearSearch="clearContactSearch"
            />
          </div>
          <div class="text-danger error-text" v-show="invalidFields.contact">
            <small>{{ invalidFields.contact }}</small>
          </div>
        </div>
        <div v-else>
          <div class="section-title">
            Fulfill Moxey request from<span class="text-muted"></span>
          </div>
          <div class="input-group">
            <contact-search-item :r="requestor" />
          </div>
          <div v-if="request.productId" class="section-text">
            For: {{ request.receiptInfo.product }}
          </div>
          <div v-if="request.note && request.note !== ''" class="section-text">
            Note: {{ request.note }}
          </div>
        </div>

        <div class="section-title">
          Moxey Amount<span class="text-muted"></span>
        </div>
        <div class="input-group">
          <span class="input-group-text"
            ><i class="fak fa-mx-sign-fat fa-fw"></i
          ></span>
          <input
            type="number"
            class="form-control"
            inputmode="decimal"
            @scroll="
              (e) => {
                e.preventDefault();
              }
            "
            @keydown="
              (e) => {
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown')
                  e.preventDefault();
              }
            "
            :class="{
              'is-invalid': invalidFields.amount || invalidFields.mxBalance,
            }"
            id="amount"
            min="0"
            step="0.01"
            name="amount"
            v-model="amount"
            placeholder="Amount"
            @blur="
              () => {
                sanitizeAmount();
                validateAmount();
              }
            "
            @focus="
              () => {
                amount = undefined;
                clearError('amount', 'mxBalance');
              }
            "
            aria-label="Amount"
            :disabled="processing"
          />
        </div>
        <div class="text-danger error-text" v-show="invalidFields.mxBalance">
          <small
            >Moxey amount is greater than your current available balance of
            <i
              class="fak fa-mx-sign-fat"
              data-fa-transform="shrink-3 right-2"
            ></i
            >{{ invalidFields.mxBalance }}.</small
          >
        </div>
        <div class="text-danger error-text" v-show="invalidFields.amount">
          <small v-html="invalidFields.amount"></small>
        </div>

        <template v-if="!trialExchanges.includes(user?.profile?.exchangeId)">
          <div class="section-title">Tip<span class="text-muted"></span></div>
          <div class="input-group">
            <span class="input-group-text"
              ><i class="fas fa-dollar-sign fa-fw"></i
            ></span>
            <input
              type="number"
              class="form-control"
              inputmode="decimal"
              :class="{
                'is-invalid': invalidFields.tip || invalidFields.cashBalance,
              }"
              id="tip"
              min="0"
              step="0.01"
              name="tip"
              v-model="tip"
              placeholder="Tip"
              @scroll="
                (e) => {
                  e.preventDefault();
                }
              "
              @keydown="
                (e) => {
                  if (e.key === 'ArrowUp' || e.key === 'ArrowDown')
                    e.preventDefault();
                }
              "
              @blur="
                () => {
                  sanitizeTip();
                  validateTip();
                }
              "
              @focus="
                () => {
                  tip = undefined;
                  clearError('tip', 'cashBalance');
                }
              "
              aria-label="Tip Amount"
              :disabled="processing"
            />
          </div>
        </template>

        <div
          class="text-danger error-text"
          v-show="invalidFields.cashBalance || invalidFields.cashBalance === 0"
        >
          <small
            >Tip amount is greater than your current Cash Reserve Account
            balance of
            <i class="fas fa-dollar-sign" data-fa-transform="shrink-3"></i
            >{{ invalidFields.cashBalance }}.</small
          >
        </div>
        <div class="text-danger error-text" v-show="invalidFields.tip">
          <small>{{ invalidFields.tip }}</small>
        </div>

        <div class="section-title">Note<span class="text-muted"></span></div>
        <textarea
          class="form-control"
          id="note"
          name="note"
          v-model="note"
          rows="3"
          :disabled="processing"
        ></textarea>

        <template v-if="!trialExchanges.includes(user?.profile?.exchangeId)">
          <div
            v-if="!showScheduler && !request"
            class="text-mx-dark d-inline-block schedule"
            @click="showScheduler = true"
          >
            <i class="fa-duotone fa-calendar-clock"></i> Schedule or Make
            Recurring
          </div>
          <div v-else-if="!request" class="scheduler">
            <div
              class="header text-muted d-flex justify-content-between align-items-center"
            >
              <div>Scheduled Moxey Send</div>
              <div
                class="close"
                @click="
                  showScheduler = false;
                  scheduleDateTime = undefined;
                  recurring = false;
                "
              >
                <i class="fas fa-times text-danger"></i>
              </div>
            </div>
            <div>Date and Time to Send</div>
            <div>
              <input
                type="datetime-local"
                class="form-control"
                v-model="scheduleDateTime"
                :min="minDate"
                step="3600"
                @focus="invalidFields.schedule = undefined"
              />
            </div>
            <div class="text-danger error-text" v-show="invalidFields.schedule">
              <small>{{ invalidFields.schedule }}</small>
            </div>

            <div class="check-control align-items-center">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="recurring"
                v-model="recurring"
              />
              <label class="form-check-label" for="recurring">
                Send again on selected day each month.
              </label>
            </div>
          </div>
        </template>

        <div v-if="trialExchanges.includes(user?.profile?.exchangeId)" class="">
          <div class="alert alert-warning d-flex flex-column gap-3 mt-3">
            <div>
              Your account is currently in trial mode and is not allowed to
              utilize in app tipping.
            </div>
            <div class="fw-bolder">
              Be sure to tip using cash or another payment method when
              appropriate!
            </div>
            <div>
              To gain access to in app tipping, you'll need to upgrade to full
              Moxey membership.
            </div>
          </div>
        </div>

        <div class="form-bottom">
          <div class="text-danger mt-2" v-show="transactionError">
            <small v-if="transactionError === 'LOW_CRA'">
              The amount in your Cash Reserve Account is not enough to cover
              this transaction. <br /><br />Never get denied for Cash Reserve
              shortage again.
              <router-link to="/banking/loadcra"
                >Click here to set up auto reload for your Cash Reserve
                Account.</router-link
              ><strong>Set it and forget it!</strong>
            </small>
            <small v-else
              >Unable to process your transaction at this time. Reason:
              {{ transactionError }}</small
            >
          </div>

          <button
            v-show="!processing"
            type="submit"
            class="btn btn-success"
            :disabled="processing"
            @click="doValidate"
          >
            {{ showScheduler ? "Schedule Moxey Send" : "Send Moxey" }}
          </button>
          <button v-show="processing" class="btn btn-success" disabled>
            <i class="fas fa-sync fa-spin"></i>
            {{ showScheduler ? "Scheduling Moxey Send" : "Sending Moxey" }}
          </button>
          <button
            class="btn btn-secondary"
            type="button"
            :disabled="processing"
            @click="backToAccount"
          >
            Cancel
          </button>
        </div>
      </form>
      <div v-if="receipt" class="receipt">
        <div v-if="receipt.scheduled" class="section-title no-padding">
          You have scheduled for Moxey to be sent to {{ receipt.sendTo }}.
        </div>
        <div v-else class="section-title no-padding">
          You sent Moxey to {{ receipt.sendTo }}.
        </div>
        <div v-if="receipt.scheduled" class="section-text">
          Moxey will be sent on {{ receipt.scheduled }}.
        </div>
        <div v-if="receipt.recurring" class="section-text">
          Moxey will be sent each month at the same date and time until
          cancelled.
        </div>
        <div class="section-text">Transaction details:</div>
        <div class="transaction-details">
          <div class="section-text">
            Amount:
            <i
              class="fak fa-mx-sign-fat"
              data-fa-transform="shrink-3 right-2"
            ></i
            >{{ receipt.amount }}
          </div>
          <div class="section-text">
            Tip: <i class="fas fa-dollar-sign" data-fa-transform="shrink-3"></i
            >{{ receipt.tip }}
          </div>
          <div class="section-text">
            Commission:
            <i class="fas fa-dollar-sign" data-fa-transform="shrink-3"></i
            >{{ receipt.fee }}
          </div>
          <div class="section-text">Date: {{ receipt.date }}</div>
          <div class="section-text">
            Confirmation: {{ receipt.moxeyPayConfirm }}
          </div>
          <div class="section-text">Note: {{ receipt.note }}</div>
          <div v-if="receipt.autoReload" class="section-text text-success">
            <em>
              Cash Reserve Account was automatically reloaded by ${{
                receipt.autoReload
              }}.
            </em>
          </div>
        </div>
        <div class="section-text">
          <span class="receipt-print" @click="printDiv('receipt-print')">
            Print receipt for your records
          </span>
        </div>

        <div
          v-if="!craEnrollment"
          class="mx-alert d-flex align-items-center mt-4 mb-3"
        >
          <div>
            <span class="fa-layers fa-fw">
              <i class="fa-solid fa-dollar-sign"></i>
              <i
                class="fa-solid fa-up text-success"
                data-fa-transform="shrink-5 right-5 down-6"
              ></i>
            </span>
          </div>
          <div class="alert-text">
            You are currently
            <span class="fw-bold text-success">NOT ENROLLED</span> in Cash
            Reserve Auto-Reload.
            <span
              class="mx-alert-link"
              @click="
                updateAppStore({
                  leftBarComp: 'AutoLoad',
                  forwardCard: undefined,
                })
              "
              >Avoid declined transactions by clicking here and enrolling
              now</span
            >.
          </div>
        </div>

        <div
          v-if="user.pointsSetup?.exchangeEnrolled && receipt.points"
          :showMyAccount="true"
          class="mt-4 mb-3"
        >
          <hr />
          <div class="py-3">
            Your purchase earned
            <reward-value :value="receipt.points" /> Community Rewards!
          </div>
        </div>

        <div v-if="showNPS" :showMyAccount="true" class="mt-4 mb-3">
          <hr />
          <div class="py-3">
            <NPS :showMyAccount="true" source="Completed Transaction" />
          </div>
          <hr />
        </div>

        <button class="btn btn-secondary close-btn" @click="clearReceipt">
          Close
        </button>

        <div id="receipt-print">
          <h3 class="header-like">Moxey Sent!</h3>
          <p v-if="receipt.scheduled">
            You have scheduled for Moxey to be sent to {{ receipt.sendTo }}.
          </p>
          <p v-else>You sent Moxey to {{ receipt.sendTo }}.</p>
          <p v-if="receipt.scheduled">
            Moxey will be sent on {{ receipt.scheduled }}.
          </p>
          <p v-if="receipt.recurring">
            Moxey will be sent each month at the same date and time until
            cancelled.
          </p>
          <p>Transaction details:</p>
          <p>
            Amount:
            <i
              class="fak fa-mx-sign-fat"
              data-fa-transform="shrink-3 right-2"
            ></i
            >{{ receipt.amount }}
          </p>
          <p>Tip: ${{ receipt.tip }}</p>
          <p>Commission: ${{ receipt.fee }}</p>
          <p>Date: {{ receipt.date }}</p>
          <p>Confirmation: {{ receipt.moxeyPayConfirm }}</p>
          <p>Note: {{ receipt.note }}</p>
          <p v-if="receipt.autoReload">
            Cash Reserve Account was automatically reloaded by ${{
              receipt.autoReload
            }}.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { log, api, gaEvent, gaError } from "@/utils/utils";
import { unpackContactSearchResult } from "@/utils/search";
import ContactSearch from "@/components/CashApp/Shared/ContactSearch.vue";
import ContactSearchItem from "@/components/CashApp/Shared/ContactSearchItem.vue";
import currency from "currency.js";
import dayjs from "dayjs";
import NPS from "../Survey/NPS.vue";
import RewardValue from "../Shared/rewards/RewardValue.vue";
import Goodbye from "../Shared/Goodbye.vue";

export default {
  name: "SendMx",
  components: {
    ContactSearch,
    ContactSearchItem,
    NPS,
    RewardValue,
    Goodbye,
  },
  data() {
    return {
      sendTo: undefined,
      amount: undefined,
      tip: undefined,
      note: "",
      invalidFields: {},
      processing: false,
      transactionError: undefined,
      receipt: undefined,
      clearContactSearch: 0,
      showScheduler: false,
      scheduleDateTime: undefined,
      recurring: false,
      request: undefined,
      requestor: undefined,
    };
  },
  props: {},
  computed: {
    minDate() {
      return dayjs(Date.now()).toISOString().substring(0, 16);
    },
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      user: (state) => state.app.all.user,
      userIcon: (state) => state.app.all.userIcon,
      memberIcon: (state) => state.app.all.memberIcon,
      payload: (state) => state.app.all.leftBarPayload,
      showNPS: (state) => state.app.all.showNPS,
      testMode: (state) => state.app.all.testMode,
      trialExchanges: (state) => state.app.all.trialAccountExchanges,
    }),
    craEnrollment() {
      for (const k in this.user?.wallet) {
        if (this.user.wallet[k]?.autoReloadAmount) {
          return this.user.wallet[k];
        }
      }
      return false;
    },
  },
  watch: {},
  methods: {
    logit(e) {
      console.log(e);
    },
    clearReceipt() {
      this.receipt = undefined;
    },
    handleSelectedContact(c) {
      this.sendTo = c;
    },
    clearForm() {
      this.sendTo = undefined;
      this.amount = undefined;
      this.tip = undefined;
      this.note = "";
      this.invalidFields = {};
      this.transactionError = false;
      this.clearContactSearch = this.clearContactSearch + 1;
      this.showScheduler = false;
      this.scheduleDateTime = undefined;
      this.recurring = false;
      this.request = undefined;
      this.requestor = undefined;
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
    doValidate() {
      this.transactionError = undefined;
      this.invalidFields = {};

      if (!this.sendTo)
        this.invalidFields.contact = "You must select a member to send to.";

      if (
        (!this.tip || this.tip === "" || this.tip === 0 || this.request) &&
        (!this.amount || this.amount === 0 || this.amount === "")
      )
        this.invalidFields.amount = "You must specify an amount to send.";
      else if (this.amount < 0)
        this.invalidFields.amount = "Moxey may not be less than zero.";
      else if (this.request && this.request.amount > this.amount)
        this.invalidFields.amount = `
          Moxey amount cannot be less than the <span class="nowrap"><i
              class="fak fa-mx-sign-fat"
              data-fa-transform="shrink-3 right-2"
            ></i
            >${this.request.amount}</span> requested.`;

      if (currency(this.amount).value > this.user.account.availableBalance)
        this.invalidFields.mxBalance = currency(
          this.user.account.availableBalance
        );

      // if (
      //   currency(this.tip).value > this.user.account.cashBalance &&
      //   !this.craEnrollment
      // ) {
      //   this.invalidFields.cashBalance =
      //     currency(this.user.account.cashBalance) || 0;
      // }

      if (this.tip < 0)
        this.invalidFields.tip = "Tip amount may not be less than zero.";

      if (
        this.showScheduler &&
        (!this.scheduleDateTime || !dayjs(this.scheduleDateTime).isValid())
      ) {
        this.invalidFields.schedule =
          "A valid future date is required for scheduled sends.";
      }

      if (Object.keys(this.invalidFields).length === 0) {
        this.sendMoxey();
      }
    },
    validateAmount() {
      if (
        (!this.tip || this.tip === "" || this.tip === 0 || this.request) &&
        (!this.amount || this.amount === 0 || this.amount === "")
      )
        this.invalidFields.amount = "You must specify an amount to send.";
      else if (this.amount < 0)
        this.invalidFields.amount = "Moxey amount must be greater than zero.";
      else if (this.request && this.request.amount > this.amount)
        this.invalidFields.amount = `
          Moxey amount cannot be less than the <span class="nowrap"><i
              class="fak fa-mx-sign-fat"
              data-fa-transform="shrink-3 right-2"
            ></i
            >${this.request.amount}</span> requested.`;

      if (currency(this.amount).value > this.user.account.availableBalance)
        this.invalidFields.mxBalance = currency(
          this.user.account.availableBalance
        );
    },
    validateTip() {
      // if (currency(this.tip).value > this.user.account.cashBalance) {
      //   this.invalidFields.cashBalance =
      //     currency(this.user.account.cashBalance) || 0;
      // }

      if (this.tip < 0)
        this.invalidFields.tip = "Tip amount may not be less than zero.";
    },
    sanitizeAmount() {
      if (this.amount < 0) {
        this.invalidFields.amount = "Moxey amount must be greater than zero.";
        this.amount = "";
      } else if (!this.amount || this.amount === "") {
        this.amount = currency(0);
      } else if (!/^\d*\.?\d*$/.test(this.amount)) {
        this.invalidFields.amount = "Invalid amount.";
        this.amount = currency(0);
      } else {
        this.amount = currency(this.amount);
      }
    },
    sanitizeTip() {
      if (this.tip < 0) {
        this.invalidFields.tip = "Tip amount must be greater than zero.";
        this.tip = "";
      } else if (!this.tip || this.tip === "") {
        this.tip = currency(0);
      } else if (!/^\d*\.?\d*$/.test(this.tip)) {
        this.invalidFields.tip = "Invalid tip.";
        this.tip = currency(0);
      } else {
        this.tip = currency(this.tip);
      }
    },
    clearError(k, l) {
      this.invalidFields[k] = undefined;
      this.invalidFields[l] = undefined;
    },
    async sendMoxey() {
      this.processing = true;

      const buyerInfo = {
        buyerUserName: [this.user.contact.fName, this.user.contact.lname].join(
          " "
        ),
        buyerMemberName: this.user.member.public.listName,
        buyerUserIcon: this.userIcon,
        buyerMemberIcon: this.memberIcon,
      };

      const sellerInfo = {
        sellerMainName: this.sendTo.list_name,
        sellerSubName:
          this.sendTo.id.substring(0, 1) === "c" && this.sendTo.desc,
        sellerMainIcon: this.sendTo.contactIcon,
        sellerSubIcon:
          this.sendTo.id.substring(0, 1) === "c" && this.sendTo.subIcon,
      };

      console.log(this.sendTo);
      try {
        if (this.testMode)
          throw { error: "This is a test account and will not send Moxey" };
        const res = await api("post", "banking", `/v2/moxey/send/`, {
          body: {
            sendTarget: this.sendTo.id,
            amount: this.amount,
            tip: this.tip,
            note: this.note,
            recurring: this.recurring,
            scheduledDateTime:
              this.scheduleDateTime &&
              dayjs(this.scheduleDateTime).toISOString(),
            receiptInfo: {
              ...buyerInfo,
              ...sellerInfo,
              requestInfo: this.request,
            },
          },
        });

        console.log(res.account);

        if (res.account) {
          this.updateAppStore({
            user: {
              ...this.user,
              account: res.account,
            },
          });
        } else {
          this.updateAppStore({
            user: {
              ...this.user,
              account: this.user.account,
            },
          });
        }

        if (res.completedRequest) {
          this.updateAppStore({
            user: {
              ...this.user,
              contact: {
                ...this.user.contact,
                pendingNotifications:
                  this.user.contact.pendingNotifications - 1,
              },
            },
          });
        }

        this.receipt = {
          sendTo: this.sendTo.list_name,
          amount: currency(this.amount, { symbol: "" }).format(),
          tip: currency(this.tip, { symbol: "" }).format(),
          fee: currency(res.fee, { symbol: "" }).format(),
          date: dayjs(Date.now()).format("M/D/YYYY h:mm A"),
          note: this.note,
          points: res.points,
          scheduled:
            this.scheduleDateTime &&
            dayjs(this.scheduleDateTime).format("M/D/YYYY h:mm A"),
          recurring: this.recurring,
          autoReload: res.autoReload,
          moxeyPayConfirm: res.moxeyPayConfirm,
        };

        //Move banking to server!
        gaEvent(
          this.scheduleDateTime ? "send_moxey_scheduled" : "send_moxey",
          "banking",
          `${buyerInfo.buyerUserName} (${buyerInfo.buyerMemberName}) => ${sellerInfo.sellerSubName} (${sellerInfo.sellerMainName}) amt:${this.amount} tip:${this.tip}`
        );

        log({
          m:
            this.scheduleDateTime && this.recurring
              ? "Recurring transaction scheduled."
              : this.scheduleDateTime
                ? "One time transaction scheduled"
                : this.request
                  ? "Fulfilled request."
                  : "Sent Moxey.",
          amt: this.amount,
          tip: this.tip,
          pid: this.sendTo.id,
          mn:
            this.sendTo.id.substring(0, 1) === "m"
              ? this.sendTo.list_name
              : this.sendTo.desc,
          cn:
            this.sendTo.id.substring(0, 1) === "m"
              ? this.sendTo.desc
              : this.sendTo.list_name,
          partition: "banking",
        });

        this.clearForm();
      } catch (e) {
        gaError(`send_moxey: ${e.error}`);
        if (e.error === "Insufficient CRA") {
          this.transactionError = "LOW_CRA";
        } else {
          this.transactionError = e.error;
        }
        log({
          m: "Error sending moxey.",
          amt: this.amount,
          tip: this.tip,
          pid: this.sendTo.id,
          mn:
            this.sendTo.id.substring(0, 1) === "m"
              ? this.sendTo.list_name
              : this.sendTo.desc,
          cn:
            this.sendTo.id.substring(0, 1) === "m"
              ? this.sendTo.desc
              : this.sendTo.list_name,
          e: e.error || e.statusCode,
          partition: "banking",
        });
      } finally {
        this.processing = false;
      }
    },
    printDiv(divName) {
      const printContents = document.getElementById(divName).innerHTML;
      const w = window.open();
      w.document.write(printContents);
      w.print();
      w.close();
    },
    closePane() {
      this.clearForm();
      this.clearReceipt();
      this.$emit("cancel");
    },
    backToAccount() {
      this.clearForm();
      this.clearReceipt();
      this.$emit("clickedShortcut", "Account");
    },
    doSearchFocus() {
      const top = document
        .getElementById("contact-search")
        .getBoundingClientRect().y;
      document.getElementById("left-bar").scroll({
        top: top - 55,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  beforeMount() {
    this.clearReceipt();
    this.clearForm();

    console.log(this.payload);

    if (this.payload && this.payload.scheduled) {
      this.showScheduler = true;
    }

    if (this.payload && this.payload.type === "requestMx") {
      this.request = this.payload;
      this.amount = this.request.amount;
      this.requestor = unpackContactSearchResult(this.request.requestor)[0];
      this.sanitizeAmount();
      this.handleSelectedContact(this.requestor);
    }

    if (this.payload) {
      this.updateAppStore({
        leftBarPayload: undefined,
      });
    }
  },
  mounted() {},
};
</script>

<style scoped>
#send-mx {
  background-color: white;
  color: var(--ms-dark);
  font-size: 18px;
  line-height: 22px;
  transform: translate(0, 0);
  min-height: 100%;
}
.sidebar-content {
  padding: 30px 0;
  min-height: 100%;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

form {
  position: relative;
  margin-top: 35px;
  width: 100%;
  height: 100%;
}

.input-group,
.form-control:not(.input-group .form-control),
select {
  margin: 15px 30px;
  width: calc(100% - 60px);
}

.input-group-text {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.section {
  margin: 60px 0 0;
  padding: 30px 30px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.section:last-child {
  margin: 60px 0;
}

.section-title {
  font-size: 20px;
  line-height: 24px;
  padding: 0 30px;
}

.section-title span {
  font-size: 16px;
  margin: 0 0 0 10px;
  font-style: italic;
}

.section-title:not(:first-of-type) {
  margin: 30px 0 0;
}

.form-control {
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  font-style: italic;
}

.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-check-input {
  padding: 12px;
  height: 24px;
  width: 24px;
  margin: 0;
}

.form-check-label {
  margin: 0 0 0 20px;
}

.check-control {
  margin: 15px 0;
  display: flex;
}

select {
  font-size: 18px;
  line-height: 20px;
  padding: 20px 24px;
  font-style: italic;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
}

.expiration:not(:last-of-type) {
  margin: 15px 20px 15px 0;
}

.btn-success {
  margin: 35px 0;
  border-color: var(--mx-green);
  background-color: var(--mx-green);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.btn-secondary {
  margin: 15px 0 35px;
  border-color: var(--mx-grey);
  background-color: var(--mx-grey);
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 100%;
  max-width: 475px;
}

.error {
  font-size: 18px;
  line-height: 27px;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.error-text {
  padding: 0 30px;
}

.form-bottom {
  padding: 0 30px;
}

.section-text {
  margin: 15px 0;
  padding: 0 30px;
}

.receipt-print {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}

#receipt-print {
  display: none;
}

.receipt {
  width: 100%;
  padding: 60px 30px 0;
}

.transaction-details {
  padding: 15px 15px;
}

.schedule {
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-dark);
  margin: 10px 30px 0;
}

.scheduler {
  font-size: 16px;
  margin: 10px 0 0;
  background-color: var(--mx-blue);
  padding: 30px;
}

.scheduler > div:not(.header) {
  margin: 15px 0 0;
}

.scheduler .header {
  font-size: 18px;
  font-weight: 500;
}

.scheduler .close {
  font-size: 22px;
  cursor: pointer;
}

.scheduler .form-control {
  margin: 0 0 !important;
  width: 100% !important;
  font-size: 18px;
  padding: 10px 20px;
}

.scheduler .btn-sm {
  border-radius: 10px;
  padding: 5px 20px;
}

.scheduler .error-text {
  padding: 0;
}

.no-padding {
  padding: 0;
}

.nowrap {
  white-space: nowrap;
}

.mx-alert div:first-child {
  font-size: 30px;
}

mx-alert:not(:first-child) {
  margin: 15px 0 0;
}

.alert-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  margin: 0 0 0 10px;
}

.promo-text {
  font-size: 17px;
  line-height: 20px;
  font-weight: 300;
  margin: 0 0 0 10px;
}

.promo-icon {
  font-size: 50px;
}

.mx-alert-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}
</style>
